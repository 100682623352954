import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import moment from 'moment'
import ArchivesData from '../staticdata/ArchivesData'
import TagsData from '../staticdata/TagsData'
import LayoutListPage from '../components/layoutListPage'
import { useFirebase } from '../components/firebase/FirebaseContext'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'

const pagePerCount = 20 // 変更する際はgraphalのlimitも変更

export const query = graphql`
  query IndexPage {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      limit: 20
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            description
            tags
            date
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
      totalCount
    }
  }
`
const Index = ({ data }) => {
  const page = 1
  const totalPage = Math.ceil(data.allMarkdownRemark.totalCount / pagePerCount)
  const postItems = []
  const oldPath = totalPage !== 1 ? '/blog/latest/2' : null

  useFirebase(firebase => {
    firebase.analytics().setCurrentScreen(window.location.pathname)
  }, [])

  data.allMarkdownRemark.edges.forEach(item => {
    postItems.push({
      slug: item.node.fields.slug,
      imgObj: item.node.frontmatter.image.childImageSharp.fluid,
      title: item.node.frontmatter.title,
      date: moment(item.node.frontmatter.date).format('YYYY/MM/DD'),
      description: item.node.frontmatter.description,
      tags: item.node.frontmatter.tags.map(tag => {
        return { name: tag }
      }),
    })
  })

  return (
    <>
      <Helmet>
        <title>メイキットシステム</title>
        <meta name="description" content="iOS, Android, NodeJsなど技術に関する情報をまとめているサイトです。便利なアプリも作成しています。" />
      </Helmet>
      <Header activeType={0} />
      <LayoutListPage
        title="メイキットシステム"
        subTitle="- iOS, Android, Webアプリケーション開発 -"
        page={page}
        totalPage={totalPage}
        nextPath={null}
        oldPath={oldPath}
        tagItems={TagsData()}
        archiveList={ArchivesData()}
        postItems={postItems}
      />
      <Footer />
    </>
  )
}

export default Index
